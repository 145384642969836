<template>
  <div>
    <!-- 页面一 -->
    <div class="GL_declareForm Gl_input">
      <Crumbs :title="'所在企业情况'"></Crumbs>
      <Form
        class="form"
        ref="formValidate"
        :model="formValidate"
        :label-width="0"
      >
        <Row>
          <Col class="col_unit" span="24">单位：万元、个、%</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业名称</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.companyName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              税务登记区
            </Col>
            <Col class="m_col" span="8">
              <Select
                disabled
                v-model="formValidate.companyInformation.registeredArea"
              >
                <Option
                  v-for="item in areaList"
                  :key="item.dataId"
                  :value="item.dataId"
                  >{{ item.dataValue }}</Option
                >
              </Select>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业注册地址</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.registeredAddress"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">所属行业</Col>
            <Col class="m_col" span="8">
              <Select
                disabled
                v-model="formValidate.companyInformation.industry"
              >
                <Option
                  :key="index"
                  v-for="(item, index) in tradeList"
                  :value="item.dataId"
                  >{{ item.dataValue }}</Option
                >
              </Select>
            </Col>
          </Row>
          <!-- <Row type="flex" class="m_row">

          </Row> -->
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业经营地址</Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model="formValidate.companyInformation.businessAddress"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 基本情况 -->
        <Row>
          <Col span="24">基本情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业注册类型</Col>
            <Col class="m_col" span="20">
              <div style="width:100%">
                <RadioGroup
                  class="RadioGroup"
                  size="large"
                  v-model="
                    formValidate.companyInformation.unitRegistrationTypeParentId
                  "
                >
                  <Radio
                    disabled
                    :key="index"
                    v-for="(item, index) in formValidate.companyType"
                    :label="item.dictId"
                    >{{ item.dictName }}</Radio
                  >
                </RadioGroup>
                <br />
                <div
                  v-show="childrenList.length > 0"
                  style="border-top:1px dashed #ccc;margin:5px 0"
                ></div>
                <RadioGroup
                  v-show="childrenList.length > 0"
                  class="RadioGroup"
                  size="large"
                  v-model="formValidate.companyInformation.allNature"
                >
                  <Radio
                    disabled
                    :key="index2"
                    v-for="(item2, index2) in childrenList"
                    :label="item2.dataId"
                    >{{ item2.dataValue }}</Radio
                  >
                </RadioGroup>
              </div>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">资产总额（万元)</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.totalAssets"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">固定资产净值（万元)</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.netWorth"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">资产负债率（%）</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.assetLiabilityRatio"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">银行信用等级</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.bankCreditRating"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">职工人数</Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model="formValidate.companyInformation.numberOfEmployees"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业是否属于</Col>
            <Col class="m_col m_padding" span="20">
              <CheckboxGroup size="large" v-model="CheckboxArray">
                <Checkbox disabled label="上市企业"></Checkbox>
                <Checkbox disabled label="高新技术企业"></Checkbox>
              </CheckboxGroup>
            </Col>
          </Row>
        </div>
        <!-- 联系方式 -->
        <Row>
          <Col span="24">联系方式</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">人员</Col>
            <Col class="m_col m_title" span="5">姓名</Col>
            <Col class="m_col m_title" span="5">职务</Col>
            <Col class="m_col m_title" span="5">手机号码</Col>
            <Col class="m_col m_title" span="5">电子邮箱</Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业负责人</Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.principalName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.principalPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.principalContactWay"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.principalEmail"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">申请联系人</Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.applyForName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.applyForPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.applyForContactWy"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.applyForContactEmail"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 上年度指标 -->
        <Row>
          <Col span="24">上年度指标</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">营业收入（万元）</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.operatingIncome"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">纳税总额（万元）</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.totalTaxOfInterest"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">利润总额（万元）</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.totalProfit"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">出口交货值（万元）</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.exportDeliveryValue"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- R＆D投入 -->
        <Row>
          <Col span="24">R＆D投入</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">R＆D投入</Col>
            <Col class="m_col m_title" span="6">2019年</Col>
            <Col class="m_col m_title" span="6">2020年</Col>
            <Col class="m_col m_title" span="6">2021年前三季度</Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">近三年投入额（万元）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.inTheForeheadOne"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.inTheForeheadTwo"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.inTheForeheadThree"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">增长率（同比）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.growthRateOne"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.growthRateTwo"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.growthRateThree"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 专利情况 -->
        <Row>
          <Col span="24">专利情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">类 别</Col>
            <Col class="m_col m_title" span="6">申请数</Col>
            <Col class="m_col m_title" span="6">授权数</Col>
            <Col class="m_col m_title" span="6">实现产业化数量</Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >专利授权数（个）</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.patentLicensingCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.companyInformation.copyrightAuthorizationCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.remark"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中：发明专利数（个）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.inventPatentMandate"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.inventCopyrightMandate"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.inventPatentRemark"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">实用新型专利数（个）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.practicalPatentApply"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.companyInformation.practicalPatentAuthorization
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.companyInformation
                    .practicalPatentIndustrialization
                "
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">外观设计专利数（个）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.appearancePatentApply"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.companyInformation.appearancePatentAuthorization
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.companyInformation
                    .appearancePatentIndustrialization
                "
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >版权数（个）</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.softPatentApply"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.companyInformation.softPatentAuthorization
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.companyInformation.softPatentIndustrialization
                "
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中：软件著作权数（个）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.copyrightApplication"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.copyrightAuthorization"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.copyrightRemark"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 主要产品 -->
        <Row>
          <Col span="24">主要产品</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">产品名称</span>
            </Col>
            <Col class="m_col m_title" span="6">产能（个）</Col>
            <Col class="m_col m_title" span="6">上年度产量（个）</Col>
            <Col class="m_col m_title" span="6">国内市场占有率（%）</Col>
          </Row>
          <Row
            v-for="(item, index) in formValidate.companyContacts"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="6">
              <Icon
                @click="reduce(index)"
                class="icon"
                type="ios-remove-circle-outline"
              />
              <Input readonly v-model="item.productName" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <Input readonly v-model="item.capacity" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="item.previousYearOutput"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="item.marketOccupancy"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 情况 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="8"
              >企业技术中心、研发中心建设情况</Col
            >
            <Col class="m_col" span="16">
              <Input
                v-model="formValidate.companyInformation.companyTheConstruction"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 8 }"
              ></Input>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="8">获国家级、省部级科技奖情况</Col>
            <Col class="m_col" span="16">
              <Input
                v-model="formValidate.companyInformation.scienceCondition"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 8 }"
              ></Input>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="8">参与制订国际、国家标准情况</Col>
            <Col class="m_col" span="16">
              <Input
                readonly
                v-model="formValidate.companyInformation.standardConditin"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 8 }"
              ></Input>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="8">质量品牌建设情况</Col>
            <Col class="m_col" span="16">
              <Input
                readonly
                v-model="formValidate.companyInformation.qualityTheConstruction"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 8 }"
              ></Input>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="8">承担国家重点工程或项目情况</Col>
            <Col class="m_col" span="16">
              <Input
                readonly
                v-model="formValidate.companyInformation.projectCase"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 8 }"
              ></Input>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="8">履行社会责任情况</Col>
            <Col class="m_col" span="16">
              <Input
                readonly
                v-model="formValidate.companyInformation.dutyCondition"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 8 }"
              ></Input>
            </Col>
          </Row>
        </div>
        <!-- 企业参与国家、本市重要设计类活动情况 -->

        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="text-align: center" span="24"
              >企业参与国家、本市重要设计类活动情况</Col
            >
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidate.companyInformation.activitySituation"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
              ></Input>
            </Col>
          </Row>
        </div>
        <!-- 企业未来两年规划情况 -->

        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="text-align: center" span="24"
              >企业未来两年规划情况</Col
            >
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidate.companyInformation.planCondition"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="简要介绍企业主要经济指标、主导产业和产品、自主创新能力建设、信息化建设、质量品牌建设等有关规划情况"
              ></Input>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <!-- 页面二 -->
    <div class="GL_declareForm Gl_input">
      <Crumbs :title="'工业设计中心情况 '"></Crumbs>
      <Form
        class="form"
        ref="formValidateTwo"
        :model="formValidateTwo"
        :label-width="0"
      >
        <Row>
          <Col class="col_unit" span="24">单位：万元、个、%</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">工业设计中心名称</Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model="
                  formValidateTwo.industrialDesignCenter.designCenterName
                "
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 基本情况 -->
        <Row>
          <Col span="24">基本情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">成立时间</Col>
            <Col class="m_col" span="8">
              <DatePicker
                readonly
                type="date"
                v-model="formValidateTwo.industrialDesignCenter.establishedTime"
                @on-change="
                  formValidateTwo.industrialDesignCenter.establishedTime = $event
                "
                placeholder="选择时间"
              ></DatePicker>
            </Col>
            <Col class="m_col m_title" span="4">
              <span>场所面积（平方米）</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.siteArea"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">资产总额（万元）</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.propertyTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span>职工人数</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.employeeCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">运营模式</Col>
            <Col class="m_col m_padding" span="20">
              <RadioGroup
                size="large"
                v-model="
                  formValidateTwo.industrialDesignCenter.operationPattern
                "
              >
                <Radio disabled label="1">独立核算</Radio>
                <Radio disabled label="2">非独立核算</Radio>
              </RadioGroup>
            </Col>
          </Row>
        </div>
        <!-- 管理人员 -->
        <Row>
          <Col span="24">管理人员</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">人员</Col>
            <Col class="m_col m_title" span="5">姓名</Col>
            <Col class="m_col m_title" span="5">职务</Col>
            <Col class="m_col m_title" span="5">手机号码</Col>
            <Col class="m_col m_title" span="5">电子邮箱</Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">负责人</Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.principalName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.principalPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.principalPhone"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.principalEmail"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">联系人</Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.contactName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.contactPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.contactPhone"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.contactEmail"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 专业人员 -->
        <Row>
          <Col span="24">专业人员</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">工业设计从业人数</Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.businessCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span>其中：本科及以上学历⼈员数</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="
                  formValidateTwo.industrialDesignCenter.educationDutyCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span>其中：本科及以上学历⼈员数占比(%) </span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.educationDuty"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span
                >具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数</span
              >
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidateTwo.industrialDesignCenter.technicianCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span
                >具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数
                占⽐(%)</span
              >
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="
                  formValidateTwo.industrialDesignCenter.technicianProportion
                "
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 投入情况 -->
        <Row>
          <Col span="24">投入情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">近三年主要指标</Col>
            <Col class="m_col m_title" span="6">2019年</Col>
            <Col class="m_col m_title" span="6">2020年</Col>
            <Col class="m_col m_title" span="6">2021年前三季度</Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">投入总额（万元）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].inputTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].inputTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].inputTotal"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">占企业R＆D支出比重</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].expenseProportion"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].expenseProportion"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].expenseProportion"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6"
              >其中：设计人员经费支出（万元）</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].expenditureExpend"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].expenditureExpend"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].expenditureExpend"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 运行情况 -->
        <Row>
          <Col span="24">运行情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">近三年主要指标</Col>
            <Col class="m_col m_title" span="6">2019年</Col>
            <Col class="m_col m_title" span="6">2020年</Col>
            <Col class="m_col m_title" span="6">2021年前三季度</Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >中心运营经费支出（万元）</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[0].centerExpenditureExpend
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[1].centerExpenditureExpend
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[2].centerExpenditureExpend
                "
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中：培训费用（万元）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].groomCost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].groomCost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].groomCost"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >工业设计服务外包额（万元）</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].serviceOutsourcing"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].serviceOutsourcing"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].serviceOutsourcing"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >承担工业设计项目数</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].assumeProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].assumeProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].assumeProjectCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中：完成项目数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].finishProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].finishProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].finishProjectCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">产业化项目数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[0].industrializationProjectCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[1].industrializationProjectCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[2].industrializationProjectCount
                "
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >拥有自主知识产权成果数</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[0].intellectualPropertyCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[1].intellectualPropertyCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[2].intellectualPropertyCount
                "
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中：产业化成果数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[0].industrializationResultCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[1].industrializationResultCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[2].industrializationResultCount
                "
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >专利授权数</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].patentLicensingCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].patentLicensingCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].patentLicensingCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中:发明专利数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].inventPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].inventPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].inventPatentCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">实用新型专利数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].practicalPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].practicalPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].practicalPatentCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">外观设计专利数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].appearancePatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].appearancePatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].appearancePatentCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >版权数</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[0].softPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[1].softPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidateTwo.inputInfoList[2].softPatentCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中：软件著作权数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[0].copyrightAuthorization
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[1].copyrightAuthorization
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidateTwo.inputInfoList[2].copyrightAuthorization
                "
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <!-- 页面三 -->
    <div class="resourcePerformance GL_declareForm Gl_input">
      <Crumbs :title="'资源及绩效 '"></Crumbs>
      <Form
        class="form"
        ref="formValidateThree"
        :model="formValidateThree"
        :label-width="0"
      >
        <Row>
          <Col class="col_unit" span="24">单位：万元、%</Col>
        </Row>
        <!-- 设计成果获奖情况 -->
        <Row>
          <Col span="24">设计成果获奖情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">获奖作品</span>
            </Col>
            <Col class="m_col m_title" span="6">奖项名称</Col>
            <Col class="m_col m_title" span="6">获得时间</Col>
            <Col class="m_col m_title" span="6">授奖部门(或机构)</Col>
          </Row>
          <Row
            v-for="(item, index) in formValidateThree.resourceAndPerfInfo
              .awardsInfoList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="6">
              <Icon
                @click="reduce('awardsInfoList', index)"
                class="icon"
                type="ios-remove-circle-outline"
              />
              <Input readonly v-model="item.awardsWorks" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <Input readonly v-model="item.awardsName" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <DatePicker
                readonly
                v-model="item.obtainTime"
                @on-change="item.obtainTime = $event"
                type="date"
                placeholder="选择时间"
                style="width: 200px"
              ></DatePicker>
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="item.authorizationDepartment"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 主要设计成果 -->
        <Row>
          <Col span="24">主要设计成果</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">项目名称</span>
            </Col>
            <Col class="m_col m_title" span="6">客户企业</Col>
            <Col class="m_col m_title" span="6">完成交付时间</Col>
            <Col class="m_col m_title" span="6">设计成果产业化及效果</Col>
          </Row>
          <Row
            type="flex"
            v-for="(item, index) in formValidateThree.resourceAndPerfInfo
              .designResultList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="6">
              <Icon
                @click="reduce('designResultList', index)"
                class="icon"
                type="ios-remove-circle-outline"
              />
              <Input readonly v-model="item.projectName" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <Input readonly v-model="item.clientCompany" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <!-- <Input v-model="item.time" class="unit_input" /> -->
              <DatePicker
                readonly
                v-model="item.finishDeliveryTime"
                @on-change="item.finishDeliveryTime = $event"
                type="date"
                placeholder="选择时间"
                style="width: 200px"
              ></DatePicker>
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="item.industrializationResult"
                class="unit_input"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
              />
            </Col>
          </Row>
        </div>
        <!-- 主要硬件设施 -->
        <Row>
          <Col span="24">主要硬件设施</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="5">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">仪器设备名称</span>
            </Col>
            <Col class="m_col m_title" span="4">台（套）数</Col>
            <Col class="m_col m_title" span="5">价值（万元）</Col>
            <Col class="m_col m_title" span="5">设备完好率（%）</Col>
            <Col class="m_col m_title" span="5">使用情况</Col>
          </Row>
          <Row
            type="flex"
            v-for="(item, index) in formValidateThree.resourceAndPerfInfo
              .hardwareFacilityList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="5">
              <Icon
                @click="reduce('hardwareFacilityList', index)"
                class="icon"
                type="ios-remove-circle-outline"
              />
              <Input readonly v-model="item.apparatusName" class="unit_input" />
            </Col>
            <Col class="m_col" span="4">
              <Input readonly v-model="item.numberOfUnits" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
              <Input readonly v-model="item.cost" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="item.facilityIntactRate"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                readonly
                v-model="item.useInfo"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 主要软件 -->
        <Row>
          <Col span="24">主要软件</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="5">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">软件名称</span>
            </Col>
            <Col class="m_col m_title" span="4">数量（套）</Col>
            <Col class="m_col m_title" span="5">价值（万元）</Col>
            <Col class="m_col m_title" span="5">使用情况</Col>
            <Col class="m_col m_title" span="5">备注</Col>
          </Row>
          <Row
            type="flex"
            v-for="(item, index) in formValidateThree.resourceAndPerfInfo
              .softwareList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="5">
              <Icon
                @click="reduce('softwareList', index)"
                class="icon"
                type="ios-remove-circle-outline"
              />
              <Input readonly v-model="item.softwareName" class="unit_input" />
            </Col>
            <Col class="m_col" span="4">
              <Input readonly v-model="item.quantity" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
              <Input readonly v-model="item.cost" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
              <Input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                readonly
                v-model="item.useInfo"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input readonly v-model="item.remark" class="unit_input" />
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <!-- 页面四 -->
    <div class="statusPlanning GL_declareForm Gl_input">
      <Crumbs :title="'现状及规划 '"></Crumbs>
      <Form
        class="form"
        ref="formValidateFour"
        :model="formValidateFour"
        :label-width="0"
      >
        <!-- 设计成果获奖情况 -->
        <Row>
          <Col span="24">设计成果获奖情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="text-align: center" span="24"
              >中心运营等有关情况</Col
            >
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidateFour.statusQuoPlan.operationInfo"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="重点是中心现在的组织体系、运营模式、产学研合作、专业人员培训及工业设计社会化服务等有关情况(3000字以内)"
              ></Input>
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="text-align: center" span="24"
              >中心今后两年目标与规划情况</Col
            >
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidateFour.statusQuoPlan.targetAndPlan"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="重点是中心今后两年创新建设、有效投入、设计成果等主要指标，以及组织体系建设、运营模式创新、人才队伍建设、履行工业设计公共服务平台职责等规划和措施等情况(3000字以内)"
              ></Input>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <!-- 页面五 -->
    <div class="personnel GL_declareForm Gl_input">
      <Form
        class="form"
        ref="formValidateFive"
        :model="formValidateFive"
        :label-width="0"
      >
        <!-- 工业设计团队带头人及主要成员情况 -->
        <!-- 基本情况 -->
        <div
          class="forItem"
          :key="index"
          v-for="(item, index) in formValidateFive.foregoerMemberList"
        >
          <Crumbs :title="'工业设计团队带头人及主要成员情况 '"></Crumbs>
          <Row>
            <Col span="24">基本情况</Col>
          </Row>
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">姓名</Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.peopleName" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
                <span>性别</span>
              </Col>
              <Col class="m_col" span="8">
                <!-- <Input v-model="item.gender" class="unit_input" /> -->
                <RadioGroup size="large" v-model="item.gender">
                  <Radio disabled label="2">男</Radio>
                  <Radio disabled label="1">女</Radio>
                </RadioGroup>
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">出生年月</Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.dateOfBirth" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
                <span>国籍</span>
              </Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.nationality" class="unit_input" />
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">公司职务</Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.companyPost" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
                <span>手机号码</span>
              </Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.phone" class="unit_input" />
              </Col>
            </Row>
          </div>
          <!-- 职称学历 -->
          <Row>
            <Col span="24">职称学历</Col>
          </Row>
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">专业职称</Col>
              <Col class="m_col" span="20">
                <Input readonly v-model="item.specialty" class="unit_input" />
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">
                学历、学位
                <br />（毕业院校及专业）
              </Col>
              <Col class="m_col" span="20">
                <Input readonly v-model="item.education" class="unit_input" />
              </Col>
            </Row>
          </div>
          <!-- 工作经历及成绩 -->
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">工作经历及成绩</Col>
              <Col class="m_col" span="20">
                <Input
                  readonly
                  v-model="item.workExperience"
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 5 }"
                  placeholder="从事工业设计领域的主要工作经历和取得的成绩"
                ></Input>
              </Col>
            </Row>
          </div>
        </div>
        <!-- 工业设计团队带头人及主要成员情况 -->
      </Form>
    </div>
    <!-- 页面六 -->
    <div class="attachmentUpload GL_declareForm">
      <Crumbs :title="'附件上传 '"></Crumbs>
      <Form
        class="form"
        ref="formValidateSix"
        :model="formValidateSix"
        :label-width="0"
      >
        <div class="m_item">
          <Row
            :key="item.sfId"
            v-for="item in formValidateSix.programFiles"
            type="flex"
            class="m_row"
          >
            <Col style=" padding:8px" class="m_col m_title" span="8">
              <span class="spantext">{{ item.declareMaterialName }}</span>
            </Col>
            <Col class="s_col" span="16">
              <template v-for="(item2, index) in item.programFilesList">
                <div class="picText" :key="index">
                  <p>{{ item2.fileName }}</p>
                  <div @click="preview(item2.filePath)">
                    <Icon type="ios-eye" style="color:#3385FF" size="30"></Icon>
                  </div>
                </div>
              </template>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <div style="text-align: center; ">
      <button @click="goBack" class="GL_cancel">返回</button>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
export default {
  components: {
    Crumbs
  },
  name: "",
  props: {},
  data() {
    return {
      CheckboxArray: [],
      childrenList: [],
      forEachFiles: {},
      programId: null,
      // 区域列表
      areaList: [],
      // 所属行业列表
      tradeList: [],
      specialFiles: [],
      formValidate: {
        programId: null,
        pageStatus: 1,
        declareType: 3001,
        companyInformation: {
          programId: null,
          unitRegistrationTypeParentId: null //企业注册类型
        },
        companyContacts: [
          {
            productName: null, //产品名称
            capacity: null, //产能
            previousYearOutput: null, //上年度产量
            marketOccupancy: null //国内市场占有率
          },
          {
            productName: null,
            capacity: null,
            previousYearOutput: null,
            marketOccupancy: null
          }
        ]
      },
      //   页面二
      formValidateTwo: {
        programId: null,
        pageStatus: 2,
        declareType: 3001,
        //工业设计中心情况
        industrialDesignCenter: {
          programId: null
        },
        inputInfoList: [
          //投入情况、运行情况
          {
            inputTotal: null, //投入总额
            expenseProportion: null, //占企业R＆D支出比重
            expenditureExpend: null, //其中：设计人员经费支出
            centerExpenditureExpend: null, //中心运营经费支出
            groomCost: null, //其中：培训费用
            serviceOutsourcing: null, //工业设计服务外包额
            assumeProjectCount: null, //承担工业设计项目数
            finishProjectCount: null, //其中：完成项目数
            industrializationProjectCount: null, //产业化项目数
            intellectualPropertyCount: null, //拥有自主知识产权成果数
            industrializationResultCount: null, //其中：产业化成果数
            patentLicensingCount: null, //专利授权数
            inventPatentCount: null, //其中:发明专利数
            copyrightAuthorization: null, //版权授权数
            year: 2019 //年份
          },
          {
            inputTotal: null,
            expenseProportion: null,
            expenditureExpend: null,
            centerExpenditureExpend: null,
            groomCost: null,
            serviceOutsourcing: null,
            assumeProjectCount: null,
            finishProjectCount: null,
            industrializationProjectCount: null,
            intellectualPropertyCount: null,
            industrializationResultCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            copyrightAuthorization: null,
            year: 2020
          },
          {
            inputTotal: null,
            expenseProportion: null,
            expenditureExpend: null,
            centerExpenditureExpend: null,
            groomCost: null,
            serviceOutsourcing: null,
            assumeProjectCount: null,
            finishProjectCount: null,
            industrializationProjectCount: null,
            intellectualPropertyCount: null,
            industrializationResultCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            copyrightAuthorization: null,
            year: 2021
          }
        ]
      },
      //   页面三
      formValidateThree: {
        programId: null,
        pageStatus: 3,
        declareType: "3001",
        resourceAndPerfInfo: {
          awardsInfoList: [
            {
              awardsWorks: null,
              awardsName: null,
              obtainTime: null,
              authorizationDepartment: null,
              programType: 1
            }
          ],
          designResultList: [
            {
              projectName: null,
              clientCompany: null,
              finishDeliveryTime: null,
              industrializationResult: null,
              programType: 1
            }
          ],
          hardwareFacilityList: [
            {
              apparatusName: null,
              numberOfUnits: null,
              cost: null,
              facilityIntactRate: null,
              useInfo: null,
              programType: 1
            }
          ],
          softwareList: [
            {
              softwareName: null,
              quantity: null,
              cost: null,
              useInfo: null,
              remark: null,
              programType: 1
            }
          ]
        }
      },
      //   页面四
      formValidateFour: {
        programId: null,
        pageStatus: 4,
        declareType: 3001,
        statusQuoPlan: {
          programId: null,
          operationInfo: null,
          targetAndPlan: null,
          programType: 1
        }
      },
      //   页面五
      formValidateFive: {
        programId: null,
        pageStatus: 5,
        declareType: 3001,
        foregoerMemberList: [
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 1
          },
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 1
          }
        ]
      },
      //   页面六
      formValidateSix: {
        programId: null,
        pageStatus: 6,
        declareType: 3001,
        programFiles: []
      }
    };
  },
  created() {
    this.getRradeList();
    this.pageOne();
    this.pageTwo();
    this.pageThree();
    this.pageFour();
    this.pageFive();
    this.pageSix();
    this.initAttachment();
    // 获取区域列表
    this.getAreaList();
  },
  mounted() {},
  methods: {
    /*获取行业列表
     *method getRradeList
     */
    async getRradeList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 4000
        }
      );
      this.tradeList = result.dictDataList;
    },
    /*获取区域列表
     *method getAreaList
     */
    async getAreaList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 8000
        }
      );
      this.areaList = result.dictDataList;
    },
    /*初始化专项附件
     *method  initAttachment
     **/
    async initAttachment() {
      var res = await this.$http.post("shsj_declare/sysDict/getSpecialFile", {
        specialId: this.$route.query.specialId,
        declareType: 3001
      });
      if (res.messageCode == 10000) {
        this.specialFiles = res.result.specialFiles;
      }
    },
    /*初始化数据
     *method queryPage
     */
    async pageOne() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 1,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidate.companyInformation =
            res.result.result.companyInformation;
          if (this.formValidate.companyInformation.companyName == null) {
            this.formValidate.companyInformation.companyName =
              localStorage.companyName;
          }
          this.formValidate.companyContacts =
            res.result.result.companyInformation.companyContacts;
          this.formValidate.companyInformation.unitRegistrationTypeParentId =
            this.formValidate.companyInformation.unitRegistrationTypeParentId -
            0;
          this.formValidate.companyInformation.allNature =
            this.formValidate.companyInformation.allNature - 0;
          this.formValidate.companyType = res.result.result.companyType;
          let index = this.formValidate.companyType.findIndex(
            item =>
              item.dictId ==
              this.formValidate.companyInformation.unitRegistrationTypeParentId
          );
          if (index == -1) {
            this.childrenList = [];
          } else {
            this.childrenList = this.formValidate.companyType[
              index
            ].sysDictDataList;
          }
          // 多选框
          if (this.formValidate.companyInformation.companyIsBelong == 1) {
            this.CheckboxArray = ["上市企业"];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 2
          ) {
            this.CheckboxArray = ["高新技术企业"];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 4
          ) {
            this.CheckboxArray = ["上市企业", "高新技术企业"];
          }
        }
      }
    },
    async pageTwo() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 2,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidateTwo.industrialDesignCenter = res.result.result;
          if (res.result.result.inputInfoList.length == 3) {
            this.formValidateTwo.inputInfoList =
              res.result.result.inputInfoList;
          }
        }
      }
    },
    async pageThree() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 3,
          declareType: 3001
        }
      );
      if (res.messageCode == 10000 && res.result.result != null) {
        this.formValidateThree.resourceAndPerfInfo = res.result.result;
      }
    },
    async pageFour() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 4,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000 && res.result.result != null) {
        if (res.result.result != null) {
          this.formValidateFour.statusQuoPlan = res.result.result;
        }
      }
    },
    async pageFive() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 5,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null && res.result.result.length > 0) {
          this.formValidateFive.foregoerMemberList = res.result.result;
        }
      }
    },
    async pageSix() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 6,
          declareType: 3001,
          specialId: this.$route.query.specialId
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidateSix.programFiles = res.result.result;
        }
      }
    },
    /** 返回
     * method goBack
     * */
    goBack() {
      this.$router.go(-1);
    },
    /*method
     **点击预览
     */

    preview(path) {
      window.open(path);
    }
  }
};
</script>

<style lang="scss" scoped>
.radiolist {
  width: 100px;
  margin-right: 4px;
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.personnel {
  .forItem {
    position: relative;
    .iconBox {
      // width: 50px;
      position: absolute;
      left: 310px;
      top: 9px;
      // right: 0;
      z-index: 888;
      .icon {
        padding: 0px 5px;
        color: #3385ff;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
.attachmentUpload {
  .spantext {
    max-width: 100%;
    display: inline-block;
    overflow-wrap: break-word;
  }
  .m_item {
    .s_col {
      border-right: 1px solid #e8ecf5;
      border-bottom: 1px solid #e8ecf5;
      .ivu-btn {
        margin: 5px;
        border-radius: 20px;
        padding: 10px 50px;
      }
      .picText {
        padding: 10px;
        display: inline-block;
      }
    }
  }
}
</style>
